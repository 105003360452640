import React from 'react';

const CustomSpinner = ({ size, strokeColor = 'orange', width = "50", height="50", viewBox="0 0 44 44" }) => (
  <>
    <div
      className="spinner-circle"
      style={{
        width: size ? `${size}px` : `${width}px`,
        height: size ? `${size}px` : `${height}px`,
      }}
    >
      <svg className="svgClass" width={width} height={height} viewBox={viewBox} >
        <circle className="circleClass" cx="22" cy="22" r="20" fill="none" strokeWidth="4" stroke={strokeColor} />
      </svg>
    </div>
    <style jsx global>
      {`
        .svgClass {
          animation: rotateAnimation 1.5s linear infinite;
        }

        .circleClass {
          stroke-dasharray: 1, 150;
          stroke-dashoffset: 0;
          stroke-linecap: round;
          animation: rotate 1.5s ease-in-out infinite;
        }

        @keyframes rotate {
          0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
            }
          50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
            }
          100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
          }
        }

        @keyframes rotateAnimation {
          100% {
            transform: rotate(360deg);
          }
        }
      `}
    </style>
  </>
);

export default CustomSpinner;
