import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import axios from 'axios';
import { withTranslation } from 'next-i18next';
import toFit from '@js/toFit';
import classNames from 'classnames';

import { useAuth } from '@context/UseAuth';
import { WARNING_POPUP_TYPE, API_URLS, NAV_PAGE } from '@js/constants';
import ContextMenu from '@components/common/ContextMenu';
import WarningPopup from '@components/common/WarningPopup';
import GlobalNavPages from '@components/GlobalNavPages';
import GlobalLinks from '@components/GlobalLinks';

const NAV_BTN_STATUS = {
  NONE: '',
  CLOSE: 'CLOSE',
  BACK: 'BACK',
};

const GlobalHeader = ({ t, className }) => {
  const noAvatarUrl = '/images/bt_profile_contents@2x.png';
  const [isUserMenu, setIsUserMenu] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [isMenuAni, setIsMenuAni] = useState(false);
  const [isWarningLogout, setIsWarningLogout] = useState(false);

  const userMenuRef = useRef();
  const logoRef = useRef();
  const [currentNavView, setCurrentNavView] = useState(NAV_PAGE.GLOBAL);
  const [navBtnStatus, setNavBtnStatus] = useState(NAV_BTN_STATUS.NONE);
  const router = useRouter();
  const { pathname, asPath } = router;
  const { user, isUserCompleted, authHeader, signout } = useAuth();

  const optimizedResize = () => {
    if (isMenu) {
      setIsMenuAni(false);
    }
    if (isMenuAni) {
      setIsMenuAni(false);
    }
  };

  const getCurrentNavView = () => currentNavView;

  const onMenuClicked = () => {
    if (navBtnStatus === NAV_BTN_STATUS.BACK) {
      setCurrentNavView(NAV_PAGE.GLOBAL);
    } else if (!isMenuAni) {
      setIsMenuAni(true);
      if (pathname.includes('/docs')) {
        setCurrentNavView(NAV_PAGE.DOCS);
      } else {
        setCurrentNavView(NAV_PAGE.GLOBAL);
      }
    } else {
      setIsMenuAni(false);
    }
  };

  const onMenuKeydown = (e) => {
    if (e?.key !== 'Escape') return;

    if (navBtnStatus === NAV_BTN_STATUS.BACK) {
      setCurrentNavView(NAV_PAGE.GLOBAL);
    } else if (!isMenuAni) {
      setIsMenuAni(true);
      if (pathname.includes('/docs')) {
        setCurrentNavView(NAV_PAGE.DOCS);
      } else {
        setCurrentNavView(NAV_PAGE.GLOBAL);
      }
    } else {
      setIsMenuAni(false);
    }
  };

  const onUserClicked = () => {
    setIsUserMenu(!isUserMenu);
  };

  const onLogoutClicked = (e) => {
    e.preventDefault();
    setIsUserMenu(false);
    setIsWarningLogout(true);
  };

  const onMypageClicked = (e) => {
    e.preventDefault();
    setIsUserMenu(false);
    router.push('/my-page');
  };

  const onWarningLogoutOKClicked = () => {
    const options = {
      crossDomain: true,
      headers: authHeader(),
    };

    axios
      .get(API_URLS.LOGOUT, options)
      .then(() => {
        signout(() => {
          setTimeout(() => {
            document.location.replace('/');
          }, 0);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onWarningLogoutCancelClicked = () => {
    setIsWarningLogout(false);
  };

  const onNavContainerClicked = (e) => {
    const { classList } = e.target;
    if (classList.contains('nav-container') && isMenuAni) {
      // setIsMenu(false);
      setIsMenuAni(false);
    }
  };

  useEffect(() => {
    window.addEventListener(
      'resize',
      toFit(optimizedResize, { passive: true })
    );

    return () => {
      window.removeEventListener(
        'resize',
        toFit(optimizedResize, { passive: true })
      );
    };
  }, [optimizedResize]);

  useEffect(() => {
    setTimeout(() => {
      if (isMenuAni) {
        if (currentNavView === NAV_PAGE.GLOBAL) {
          setNavBtnStatus(NAV_BTN_STATUS.CLOSE);
        } else {
          setNavBtnStatus(NAV_BTN_STATUS.BACK);
        }
      } else {
        setNavBtnStatus(NAV_BTN_STATUS.NONE);
      }
    }, 0);
  }, [isMenuAni, currentNavView]);

  useEffect(() => {
    if (!isMenuAni) {
      setTimeout(() => {
        setIsMenu(false);
      }, 400);
    }
  }, [isMenuAni]);

  useEffect(() => {
    if (pathname) {
      // setIsMenu(false);
      setIsMenuAni(false);
      if (pathname.includes('/docs')) {
        setCurrentNavView(NAV_PAGE.DOCS);
      } else {
        setCurrentNavView(NAV_PAGE.GLOBAL);
      }
    }
  }, [pathname, asPath]);

  return (
    <>
      {/* <Head> */}
      {/*   {typeof window !== 'undefined' && process.env.NEXT_PUBLIC_HOTJAR_ID && ( */}
      {/*     <script */}
      {/*       dangerouslySetInnerHTML={{ */}
      {/*         __html: ` */}
      {/*                   (function(h,o,t,j,a,r){ */}
      {/*       h.hj = h.hj || function() { */}
      {/*         (h.hj.q = h.hj.q || []).push(arguments); */}
      {/*       }; */}
      {/*       h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6}; */}
      {/*       a=o.getElementsByTagName('head')[0]; */}
      {/*       r=o.createElement('script');r.async=1; */}
      {/*       r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; */}
      {/*       a.appendChild(r); */}
      {/*     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); */}
      {/*           `, */}
      {/*       }} */}
      {/*     /> */}
      {/*   )} */}
      {/* </Head> */}
      <header
        className={classNames(
          `mind-header fixed top-0 left-0 right-0 z-50 pt-0 h-[64px] md:h-[72px] bg-white ${className} ${
            isUserCompleted && user ? 'online' : ''
          }
        `,
          {
            'fixed w-8 h-8 top-4 left-5 z-10': isMenu,
          }
        )}
        id="mind-header"
      >
        <div className="relative max-w-none px-4 py-0 md:px-6 md:py-0 xl:px-12 xl:py-0">
          <div className="inline-block align-top text-[0px] py-4 px-0 md:py-5 md:px-0">
            <span
              role="button"
              tabIndex={0}
              className={classNames(
                'relative inline-block align-top w-8 h-8 cursor-pointer z-10 mr-0 xl:hidden'
              )}
              title={
                navBtnStatus !== NAV_BTN_STATUS.NONE
                  ? t(`btn-${navBtnStatus.toLowerCase()}`)
                  : 'Navigation'
              }
              onClick={onMenuClicked}
              onKeyDown={onMenuKeydown}
            >
              <span
                className={classNames(
                  'absolute left-2 top-[7px] block z-10 w-4 h-[2px] bg-black transition-all duration-500 origin-center ',
                  {
                    'origin-center !left-[7px] !top-[15px] w-[17px] rotate-45':
                      navBtnStatus.toLowerCase() === 'close',
                    'origin-center left-0 top-3 w-[10px] -rotate-45':
                      navBtnStatus.toLowerCase() === 'back',
                  }
                )}
              />
              <span
                className={classNames(
                  'absolute left-2 block z-10 w-4 h-[2px] bg-black transition-all duration-500 origin-center top-[15px]',
                  {
                    'origin-center !left-[7px] !top-[15px] w-[17px] -rotate-45':
                      navBtnStatus.toLowerCase() === 'close',
                    'origin-center left-0 top-[18px] w-[10px] rotate-45':
                      navBtnStatus.toLowerCase() === 'back',
                  }
                )}
              />
              <span
                className={classNames(
                  'absolute left-2 block z-10 w-3 h-[2px] bg-black transition-all duration-500 origin-center top-[23px] mb-0',
                  {
                    'w-[18px] origin-center scale-0 left-[7px]':
                      navBtnStatus.toLowerCase() === 'close',
                    'w-[18px] origin-center scale-0 left-0':
                      navBtnStatus.toLowerCase() === 'back',
                  }
                )}
              />
            </span>
            <a
              ref={logoRef}
              href="/"
              className="absolute top-auto left-[60px] xl:left-auto inline-block align-top w-8 h-8 bg-transparent bg-[url('/images/img_symbol_logo_black@4x.png')] bg-no-repeat bg-cover xl:relative xl:inline-block xl:align-top xl:top-auto xl:w-[267px] xl:h-[32px] xl:bg-[url('/images/img_logo_mind_expression@4x.png')]"
              title="Mind Expression"
              aria-label="Mind Expression"
            />
          </div>
          <div className="align-top text-0 float-right flex items-center">
            <>
              <div
                className={`nav-container fixed bg-[rgba(43,44,45,0.7)] top-0 botton-0 left-0 right-[100%] overflow-y-auto delay-0 xl:hidden ${
                  isMenuAni ? 'ani' : ''
                }`}
                onClick={onNavContainerClicked}
                onKeyDown={onNavContainerClicked}
              >
                <div className="nav-wrapper absolute top-0 left-0 right-0 bottom-0 w-[248px] xl:w-[240px] p-0 bg-white overflow-hidden transition-all duration-500 opacity-0 delay-100 -translate-x-[100%] tranlate-y-0">
                  <GlobalNavPages
                    isMenuAni={isMenuAni}
                    className={isMenuAni ? 'ani' : ''}
                    logoRef={logoRef}
                    t={t}
                    setCurrentNavView={setCurrentNavView}
                    getCurrentNavView={getCurrentNavView}
                  />
                </div>
              </div>
              {!isMenu && (
                <div className="relative hidden xl:inline-block xl:align-top xl:pr-[48px]">
                  <GlobalLinks t={t} isMenu={isMenu} />
                </div>
              )}
              {isUserCompleted ? (
                user ? (
                  <>
                    <a
                      href="/builder"
                      className="text-base font-bold -tracking-[0.4px] text-[#18aa78] block text-left border-[1px] border-solid border-[#18aa78] py-2 px-4 my-3 mr-6 rounded-sm md:my-4 xl:inline-block xl:align-top hover:text-[#228765] active:text-[#228765]"
                      id="btn-builder"
                      title="Go to Builder"
                    >
                      Go to Builder
                    </a>
                    <div className="relative xl:inline-block xl:align-top xl:ml-0 xl:-mt-1">
                      <div className="inline-block w-10 h-10 rounded-full align-top bg-white bg-[url('/images/bt_profile_contents@2x.png')] bg-no-repeat bg-cover bg-center z-0 mr-1 xl:mr-0" />
                      <div
                        role="button"
                        tabIndex={0}
                        aria-label="User Account"
                        className="absolute top-0 right-0 inline-block w-10 h-10 mr-1 rounded-full align-top content-[''] overflow-hidden text-transparent bg-white bg-no-repeat bg-cover bg-center cursor-pointer z-[1px] shadow-[0_0_0_2px_#ffffff] xl:mr-0"
                        id="btn-user"
                        title="User Account"
                        style={{
                          backgroundImage: user?.profile_image_url
                            ? `url(${user?.profile_image_url})`
                            : `url(${noAvatarUrl})`,
                        }}
                        onClick={onUserClicked}
                        onKeyDown={onUserClicked}
                        ref={userMenuRef}
                        data-testid="btn-user"
                      />
                      {isUserMenu && (
                        <ContextMenu
                          setClose={onUserClicked}
                          button={userMenuRef}
                        >
                          <ul>
                            <li className="menu-item item-mypage">
                              <a title="My Page" onClick={onMypageClicked}>
                                My Profile
                              </a>
                            </li>
                            <li className="menu-item item-logout">
                              <a title="Log Out" onClick={onLogoutClicked}>
                                Logout
                              </a>
                            </li>
                          </ul>
                        </ContextMenu>
                      )}
                    </div>
                  </>
                ) : (
                  <a
                    href="/start"
                    className="bg-white text-base font-bold -tracking-[0.4px] text-[#18aa78] block text-left border-[1px] border-solid border-[#18aa78] py-2 px-4 my-3 mr-6 rounded-sm md:my-4 hover:text-[#228765] active:text-[#228765]"
                    title="Register Now"
                  >
                    Register Now
                  </a>
                )
              ) : (
                <></>
              )}
            </>
          </div>
        </div>
      </header>
      {isWarningLogout && (
        <WarningPopup
          className="negative"
          type={WARNING_POPUP_TYPE.CONFIRM}
          title={t('header-logout-popup-title')}
          content=""
          lblOK="Log Out"
          lblCancel="Cancel"
          onOKHandled={onWarningLogoutOKClicked}
          onCancelHandled={onWarningLogoutCancelClicked}
        />
      )}
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .mind-header {
            .btn-nav {
              @media (hover: hover) {
                &:hover {
                  span {
                    opacity: 0.4;
                  }
                }
              }

              &:active {
                span {
                  opacity: 0.4;
                }
              }

              &.ani {
                position: fixed;
                width: 32px;
                height: 32px;
                top: 16px;
                left: $global_m_padding;
                z-index: 11;

                span {
                  background: #000000;
                  left: 8px;

                  &:first-child {
                    top: 16px;
                    width: 17px;
                    transform: rotate(45deg);
                    transform-origin: center center;
                  }

                  &:nth-child(2) {
                    top: 16px;
                    width: 17px;
                    transform: rotate(-45deg);
                    transform-origin: center center;
                  }

                  &:last-child {
                    top: 24px;
                    transform: scale(0, 0);
                    transform-origin: center center;
                  }
                }
              }
            }

            .nav-container {
              &.ani {
                position: fixed;
                top: 0;
                bottom: 0;
                width: 100%;
                transition-delay: 0s;

                .nav-wrapper {
                  transition: all 0.5s;
                  transform: translate(0, 0);
                  opacity: 1;
                }
              }
            }

            .menu-item {
              height: 44px;
              display: flex;
              padding-left: 22px;
              justify-content: start;
              align-items: center;

              a,
              label,
              button {
                border: none;
                text-align: left;
                display: inline-block;
                font-weight: 700;
                height: auto;
                cursor: pointer;
                @include font(16px, 600, -0.4px, 24px, inherit);

                &[disabled] {
                  color: rgba(43, 44, 45, 0.2);
                }
              }

              &:hover {
                background-color: #f4f4f4;
              }
            }

            .menu-item {
              font-size: 15px;
              a {
                padding-left: 35px;
                font-size: 15px;
                font-weight: 700;
              }

              &:first-child {
                a {
                  background: url('#{$image_url}/ic_profile/normal.svg') 0
                    center no-repeat;
                  background-size: 20px 20px;

                  @media (hover: hover) {
                    &:hover {
                      background-image: url('#{$image_url}/ic_profile/normal.svg');
                    }
                  }
                  &:active {
                    background-image: url('#{$image_url}/ic_profile/normal.svg');
                  }
                }
              }

              &:nth-child(2) {
                a {
                  background: url('#{$image_url}/ic_logout/normal.svg') 0 center
                    no-repeat;
                  background-size: 20px 20px;

                  @media (hover: hover) {
                    &:hover {
                      background-image: url('#{$image_url}/ic_logout/normal.svg');
                    }
                  }
                  &:active {
                    background-image: url('#{$image_url}/ic_logout/normal.svg');
                  }
                }
              }
            }

            @media all and (min-width: $resolution_d_min) {
              height: $header_d_height;
              padding: 0;

              .wrapper {
                padding: 0 48px;
              }

              .btn-nav {
                display: none;
              }

              .nav-container {
                .nav-wrapper {
                  width: 240px;
                }

                &.ani {
                  .nav-wrapper {
                    width: 240px;
                  }
                }
              }
            }
          }
        `}
      </style>
    </>
  );
};

GlobalHeader.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  // userValue: PropTypes.objectOf(PropTypes.any),
};

GlobalHeader.defaultProps = {
  // userValue: null,
  className: '',
};

export default withTranslation('common')(GlobalHeader);
