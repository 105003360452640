import {
  CHECK_SOURCE_TYPE,
  DEF_TEMPLATE_TYPES,
  FOCUSED_ACTION,
  PROBLEM_TYPE,
  RESPONSE_CARD_TITLE_MAX_LENGTH,
  SUBJECT_TYPE,
  TEMPLATE_TEXT_MAX_LENGTH,
  TEMPLATE_TYPES,
  CHANNEL_DISPLAY_ORDER,
} from '@js/constants';

const returnStepIcon = (step, action) => {
  if (step === 'checkstep') {
    return FOCUSED_ACTION.CHECK_WITH?.toLowerCase();
  }
  if (step === 'solvestep') {
    if (action === 'response') {
      return FOCUSED_ACTION.SHOW_RESPONSE?.toLowerCase();
    }
    if (action === 'confirmation') {
      return FOCUSED_ACTION.CONFIRMATION?.toLowerCase();
    }
    if (action === 'solution') {
      return FOCUSED_ACTION.SOLVE_VERIFY?.toLowerCase();
    }
  }
  if (step === 'satisfystep') {
    return FOCUSED_ACTION.SATISFY?.toLowerCase();
  }
};

const returnStepDesc = (step, source, action) => {
  const stepValue = step.toLowerCase();
  const sourceValue = source?.toLowerCase();
  const actionValue = action?.toLowerCase();

  if (stepValue === 'checkstep') {
    if (sourceValue === 'user') {
      return 'Check with customer';
    }
    if (sourceValue === 'webhook') {
      return 'Check with webhook';
    }
    if (sourceValue === 'both') {
      return 'Check with customer using webhook';
    }
  }
  if (stepValue === 'solvestep') {
    if (actionValue === 'response') {
      if (sourceValue === 'user') {
        return 'Show response using local information';
      }
      if (sourceValue === 'webhook') {
        return 'Show response using webhook';
      }
    }
    if (actionValue === 'confirmation') {
      if (sourceValue === 'user') {
        return 'Confirm with customer';
      }
      if (sourceValue === 'webhook') {
        return 'Confirm with webhook';
      }
      if (sourceValue === 'both') {
        return 'Confirm with customer using webhook';
      }
    }
    if (actionValue === 'solution') {
      if (sourceValue === 'user') {
        return 'Solve with customer';
      }
      if (sourceValue === 'webhook') {
        return 'Solve with webhook';
      }
      if (sourceValue === 'both') {
        return 'Solve with customer using webhook';
      }
    }
  }
  if (stepValue === 'satisfystep') {
    if (sourceValue === 'user') {
      return 'Validate result with customer';
    }
    if (sourceValue === 'webhook') {
      return 'Validate result with webhook';
    }
    if (sourceValue === 'both') {
      return 'Validate result with customer using webhook';
    }
  }
};

const returnStepDescWithParam = (title, param, type) => {
  const result = title?.toLowerCase();
  const typeWithUppercase = type?.charAt(0).toUpperCase() + type?.slice(1);
  if (result.startsWith('check')) {
    return `Checking for "${param}" ${typeWithUppercase}`;
  }
  if (result.startsWith('show')) {
    return `Showing response for "${param}" ${typeWithUppercase}`;
  }
  if (result.startsWith('confirm')) {
    return `Confirming for "${param}" ${typeWithUppercase}`;
  }
  if (result.startsWith('solve')) {
    return `Solving for "${param}" ${typeWithUppercase}`;
  }
  if (result.startsWith('validate')) {
    return `Validating result for "${param}" ${typeWithUppercase}`;
  }
};

const returnTypeName = (type) => {
  const result = (() => {
    switch (type?.toLowerCase()) {
      case 'point':
        return PROBLEM_TYPE.POINT;
      case 'dg-point':
        return PROBLEM_TYPE.DG_POINT;
      case 'selection-class':
        return PROBLEM_TYPE.SELECTION_CLASS;
      case 'selection':
        return PROBLEM_TYPE.SELECTION;
      case 'dg-selection-class':
        return PROBLEM_TYPE.DG_SELECTION_CLASS;
      case 'dg-selection':
        return PROBLEM_TYPE.DG_SELECTION;
      case 'parameter-bundle':
        return PROBLEM_TYPE.PARAM_BUNDLE;
      case 'parameter':
        return PROBLEM_TYPE.PM_REQ;
      default:
        return type;
    }
  })();

  return result?.toLowerCase();
};

export const returnKeyTypeName = (referType) => {
  let name = referType;
  switch (referType) {
    case PROBLEM_TYPE.POINT:
      name = 'Condition';
      break;
    case PROBLEM_TYPE.SELECTION_CLASS:
      name = 'Selection Class';
      break;
    case PROBLEM_TYPE.SELECTION:
      name = 'Selection';
      break;
    case PROBLEM_TYPE.DG_POINT:
      name = 'Diagnostic Condition';
      break;
    case PROBLEM_TYPE.DG_SELECTION_CLASS:
      name = 'Diagnostic Selection Class';
      break;
    case PROBLEM_TYPE.DG_SELECTION:
      name = 'Diagnostic Selection';
      break;
    case PROBLEM_TYPE.PARAM_BUNDLE:
      name = 'Parameter Bundle';
      break;
    case PROBLEM_TYPE.PM_REQ:
    case PROBLEM_TYPE.PM_OPT:
    case PROBLEM_TYPE.PM_PP:
      name = 'Parameter';
      break;
    case PROBLEM_TYPE.RESPONSE:
      name = 'Response';
      break;
    default:
      break;
  }

  return name;
};

const returnRelationName = (referType, variable) => {
  const { type, step_source } = variable;
  let name = '';
  switch (type) {
    case 'binding_key':
      name = `${returnKeyTypeName(referType)} Settings`;
      break;
    case 'checkstep_result_mapping':
      name = `Check with ${
        step_source === CHECK_SOURCE_TYPE.WEBHOOK
          ? 'webhook'
          : 'customer using webhook'
      }`;
      break;
    case 'solvestep_result_mapping':
      const { step_action } = variable;
      if (step_action === 'response') {
        name = `Show response using webhook`;
      } else if (step_action === 'solution') {
        name = `Solve with ${
          step_source === CHECK_SOURCE_TYPE.WEBHOOK
            ? 'webhook'
            : 'customer using webhook'
        }`;
      } else {
        name = `Confirm with ${
          step_source === CHECK_SOURCE_TYPE.WEBHOOK
            ? 'webhook'
            : 'customer using webhook'
        }`;
      }
      break;
    case 'satisfystep_result_mapping':
      name = `Validate result with ${
        step_source === CHECK_SOURCE_TYPE.WEBHOOK
          ? 'webhook'
          : 'customer using webhook'
      }`;
      break;
    default:
      break;
  }

  return name;
};

const returnTypeLabel = (type) => {
  switch (type) {
    case 'DG':
      return returnSubjectTypeLabel(type);
    case 'IR':
      return returnSubjectTypeLabel(type);
    case 'FS':
      return returnSubjectTypeLabel(type);
    case 'point':
      return 'Condition';
    case 'dg-point':
      return 'Diagnostic Condition';
    case 'selection-class':
      return 'Selection Class';
    case 'selection':
      return 'Selection';
    case 'dg-selection-class':
      return 'Diagnostic Selection Class';
    case 'dg-selection':
      return 'Diagnostic Selection';
    case 'parameter-bundle':
      return 'Parameter Bundle';
    case 'parameter':
      return 'Parameter';
    default:
      return type;
  }
};

const returnTypeLabelForLocal = (type) => {
  switch (type) {
    case PROBLEM_TYPE.POINT:
      return 'Condition';
    case PROBLEM_TYPE.DG_POINT:
      return 'Diagnostic Condition';
    case PROBLEM_TYPE.SELECTION_CLASS:
      return 'Selection Class';
    case PROBLEM_TYPE.SELECTION:
      return 'Selection';
    case PROBLEM_TYPE.DG_SELECTION_CLASS:
      return 'Diagnostic Selection Class';
    case PROBLEM_TYPE.DG_SELECTION:
      return 'Diagnostic Selection';
    case PROBLEM_TYPE.PARAM_BUNDLE:
      return 'Parameter Bundle';
    case PROBLEM_TYPE.PM_REQ:
      return 'Required Parameter'; // 'Required Parameter'
    case PROBLEM_TYPE.PM_OPT:
      return 'Optional Parameter'; // 'Optional Parameter'
    case PROBLEM_TYPE.PM_PP:
      return 'Principal Parameter'; // 'Principal Parameter'
    case PROBLEM_TYPE.RESPONSE:
      return 'Response';
    case 'STEP':
      return 'Step';
    default:
      return type;
  }
};

const returnActionType = (status) => {
  const result = (() => {
    if ([100, 110, 120].includes(status)) return 'check_with';
    if (status === 201) return 'greeting';
    if (status === 204) return 'error';
    if ([200, 210, 220].includes(status)) return 'show_response';
    if ([230, 240, 250].includes(status)) return 'confirmation';
    if ([260, 270, 280].includes(status)) return 'solve_verify';
    if ([300, 310, 320].includes(status)) return 'satisfy';
    if (status >= 400) return 'error';
    return 'satisfy';
  })();

  return result;
};

const returnScopeModeLabel = (mode) => {
  if (mode === 'dev') return 'Dev Scope';
  if (mode === 'prod') return 'Prod Scope';
  return mode;
};

const returnSubjectTypeLabel = (type) => {
  const result = (() => {
    switch (type) {
      case SUBJECT_TYPE.DG:
        return 'Diagnosis Subject';
      case SUBJECT_TYPE.FS:
        return 'Info Search Subject';
      case SUBJECT_TYPE.IP:
        return 'Intelligent Process Subject';
      case SUBJECT_TYPE.IR:
        return 'Info Search Subject';
      case SUBJECT_TYPE.QNA:
        return 'Q&A Subject';
      default:
        return type;
    }
  })();
  return result;
};

const returnComponentSubjectLabel = (type) => {
  switch (type) {
    case 'point':
    case PROBLEM_TYPE.POINT:
      return 'Condition';
    case 'dg-point':
    case PROBLEM_TYPE.DG_POINT:
      return 'Diagnostic Condition';
    case 'selection-class':
    case PROBLEM_TYPE.SELECTION_CLASS:
      return 'Selection Class';
    case 'selection':
    case PROBLEM_TYPE.SELECTION:
      return 'Selection';
    case 'dg-selection-class':
    case PROBLEM_TYPE.DG_SELECTION_CLASS:
      return 'Diagnostic Selection Class';
    case 'dg-selection':
    case PROBLEM_TYPE.DG_SELECTION:
      return 'Diagnostic Selection';
    case 'parameter-bundle':
    case PROBLEM_TYPE.PARAM_BUNDLE:
      return 'Parameter Bundle';
    case 'parameter':
    case PROBLEM_TYPE.PM_REQ:
    case PROBLEM_TYPE.PM_OPT:
      return 'Parameter';
    case SUBJECT_TYPE.DG:
      return 'Diagnosis Subject';
    case SUBJECT_TYPE.FS:
      return 'Query Freestyle Subject';
    case SUBJECT_TYPE.IP:
      return 'Intelligent Process Subject';
    case SUBJECT_TYPE.IR:
      return 'Info Search Subject';
    case SUBJECT_TYPE.QNA:
      return 'Q&A Subject';
    default:
      return type;
  }
};

const getChannelDataByPlatform = (channelResult, platform) => {
  const channelInstruction =
    channelResult?.['channel-instruction']?.channels.find(
      (ch) => ch?.['channel-name'] === platform
    ) ||
    channelResult?.['channel-instruction']?.channels.find(
      (ch) => ch?.['channel-name'] === 'default'
    );
  const channelInstructionAlt =
    channelResult?.['channel-instruction-alt']?.channels.find(
      (ch) => ch?.['channel-name'] === platform
    ) ||
    channelResult?.['channel-instruction-alt']?.channels.find(
      (ch) => ch?.['channel-name'] === 'default'
    );
  const channelMessage =
    channelResult?.['channel-message']?.channels.find(
      (ch) => ch?.['channel-name'] === platform
    ) ||
    channelResult?.['channel-message']?.channels.find(
      (ch) => ch?.['channel-name'] === 'default'
    );
  const channelMessageAlt =
    channelResult?.['channel-message-alt']?.channels.find(
      (ch) => ch?.['channel-name'] === platform
    ) ||
    channelResult?.['channel-message-alt']?.channels.find(
      (ch) => ch?.['channel-name'] === 'default'
    );

  return {
    channelInstruction,
    channelInstructionAlt,
    channelMessage,
    channelMessageAlt,
  };
};

const returnTemplateType = (response, platform) => {
  const channelResult = response?.meta?.data?.['channel-result']?.[0];
  const templateTypeCode =
    response?.audit_json?.audit?.model[0]?.component?.structure?.response?.code;

  const res = [];
  const instructionDetails = {
    type: templateTypeCode === 200 ? 'Response' : 'Instruction',
    response_type: 'Template',
    template_type: 'Text',
  };
  let messageDetails = null;
  const {
    channelInstruction,
    channelInstructionAlt,
    channelMessage,
    channelMessageAlt,
  } = getChannelDataByPlatform(channelResult, platform);

  if (channelInstruction) {
    switch (channelInstruction['template-type']) {
      case 'text':
        instructionDetails.template_type = 'Text';
        break;
      case 'card':
        instructionDetails.template_type = 'Card';
        break;
      case 'image':
        instructionDetails.template_type = 'Image';
        break;
      case 'datetime':
        instructionDetails.template_type = 'Date picker';
        break;
      case 'quick_reply':
        if (platform === 'viber') {
          const templateType = channelInstructionAlt?.channels.find(
            (ch) => ch?.['channel-name'] === platform
          )?.['template-type'];
          instructionDetails.response_type = 'Alternative';
          instructionDetails.template_type =
            !templateType || templateType === 'text' ? 'Text' : 'Code';
        } else {
          instructionDetails.template_type = 'Quick Replies';
        }
        break;
      case 'free':
        instructionDetails.response_type = 'Free';
        const templateType = channelInstruction?.channels.find(
          (ch) => ch?.['channel-name'] === platform
        )?.['template-type'];
        instructionDetails.template_type =
          !templateType || templateType === 'text' ? 'Text' : 'Code';
        break;
      default:
        break;
    }
  }

  if (channelMessage) {
    messageDetails = {
      type: 'Prompt',
      response_type: 'Template',
      template_type: 'Text',
    };

    switch (channelMessage['template-type']) {
      case 'text':
        messageDetails.template_type = 'Text';
        break;
      case 'card':
        messageDetails.template_type = 'Card';
        break;
      case 'image':
        messageDetails.template_type = 'Image';
        break;
      case 'quick_reply':
        if (platform === 'viber') {
          const templateType = channelMessageAlt?.channels.find(
            (ch) => ch?.['channel-name'] === platform
          )?.['template-type'];
          messageDetails.response_type = 'Alternative';
          messageDetails.template_type =
            !templateType || templateType === 'text' ? 'Text' : 'Code';
        } else {
          messageDetails.template_type = 'Quick Replies';
        }
        break;
      case 'datetime':
        messageDetails.template_type = 'Date picker';
        break;
      case 'free':
        messageDetails.response_type = 'Free';
        const templateType = channelMessage?.channels.find(
          (ch) => ch?.['channel-name'] === platform
        )?.['template-type'];
        messageDetails.template_type =
          !templateType || templateType === 'text' ? 'Text' : 'Code';
        break;
      default:
        break;
    }
  }

  if (instructionDetails) {
    res.push(instructionDetails);
  }

  if (messageDetails) {
    res.push(messageDetails);
  }

  return res;
};

const returnIsTemplateEmpty = (channelResult, platform = 'default') => {
  let isEmpty = true;

  const isTemplateTypeChannels =
    channelResult?.['channel-instruction']?.['template-type'] === 'channels';

  let channelInstruction = isTemplateTypeChannels
    ? channelResult?.['channel-instruction'].channels.find(
        (ch) => ch?.['channel-name'] === platform
      )
    : channelResult?.['channel-instruction'];

  if (channelInstruction?.['template-type'] === 'timeframe') {
    channelInstruction = channelInstruction?.message_list?.[0];
  }

  const channelInstructionAlt = channelResult?.['channel-instruction-alt'];
  let channelMessage = channelResult?.['channel-message'];

  if (channelMessage?.['template-type'] === 'timeframe') {
    channelMessage = channelMessage?.message_list?.[0];
  }

  const channelMessageAlt = channelResult?.['channel-message-alt'];

  if (channelInstruction) {
    switch (channelInstruction['template-type']) {
      case 'text':
      case 'code':
        if (channelInstruction.template) {
          isEmpty = false;
        }
        break;
      case 'card':
        if (
          channelInstruction['card-list'].length > 0 &&
          channelInstruction['card-list'].find(
            (item) =>
              item.title ||
              item?.['image-url'] ||
              item?.['sub-title'] ||
              item?.buttons?.find((btn) => btn.label)
          )
        ) {
          isEmpty = false;
        }
        break;
      case 'image':
        if (channelInstruction['image-url']) {
          isEmpty = false;
        }
        break;
      case 'video':
        if (channelInstruction['video-url']) {
          isEmpty = false;
        }
        break;
      case 'document':
        if (channelInstruction['document-url']) {
          isEmpty = false;
        }
        break;
      case 'quick_reply':
        if (
          channelInstruction['quick-replies'].length > 0 &&
          (channelInstruction.template ||
            channelInstruction['quick-replies'].find(
              (item) => item.label || item.message
            ))
        ) {
          isEmpty = false;
        }
        break;
      case 'datetime':
        isEmpty = false;
        break;
      case 'free':
        if (
          channelInstruction.channels.length > 0 &&
          channelInstruction.channels.find((ch) => ch.template)
        ) {
          isEmpty = false;
        }
        break;
      case 'whatsapp_template':
        isEmpty = false;
        break;
      case 'channels':
        isEmpty = false;
        break;

      default:
        break;
    }
  }

  if (channelInstructionAlt?.channels?.length > 0) {
    isEmpty = false;
  }

  if (channelMessage) {
    switch (channelMessage['template-type']) {
      case 'text':
      case 'code':
        if (channelMessage.template) {
          isEmpty = false;
        }
        break;
      case 'card':
        if (
          channelMessage['card-list'].length > 0 &&
          channelMessage['card-list'].find(
            (item) =>
              item.title ||
              item?.['image-url'] ||
              item?.['sub-title'] ||
              item?.buttons?.find((btn) => btn.label)
          )
        ) {
          isEmpty = false;
        }
        break;
      case 'image':
        if (channelMessage['image-url']) {
          isEmpty = false;
        }
        break;
      case 'video':
        if (channelMessage['video-url']) {
          isEmpty = false;
        }
        break;
      case 'document':
        if (channelMessage['document-url']) {
          isEmpty = false;
        }
        break;
      case 'quick_reply':
        if (
          channelMessage['quick-replies'].length > 0 &&
          (channelMessage.template ||
            channelMessage['quick-replies'].find(
              (item) => item.label || item.message
            ))
        ) {
          isEmpty = false;
        }
        break;
      case 'datetime':
        isEmpty = false;
        break;
      case 'free':
        if (
          channelMessage.channels.length > 0 &&
          channelMessage.channels.find((ch) => ch.template)
        ) {
          isEmpty = false;
        }
        break;
      case 'channels':
        isEmpty = false;
        break;
      case 'multiple':
        isEmpty = false;
        break;
      default:
        break;
    }
  }

  if (channelMessageAlt?.channels?.length > 0) {
    isEmpty = false;
  }

  return isEmpty;
};

const returnAddComma = (num) => {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ',');
};

const isValidDate = (d) => d instanceof Date && !isNaN(d);

const returnTemplate = (template, configs) => {
  const messageTemplate = { ...template };
  const tType = messageTemplate['template-type'];
  if (tType === DEF_TEMPLATE_TYPES.TEXT) {
    if (messageTemplate.content) {
      const validTexts = messageTemplate.content.filter((txt) => txt);
      messageTemplate.content = validTexts.length > 0 ? validTexts : [''];
    }
  }
  if (
    [
      DEF_TEMPLATE_TYPES.IMAGE,
      DEF_TEMPLATE_TYPES.VIDEO,
      DEF_TEMPLATE_TYPES.DOCUMENT,
    ].includes(tType)
  ) {
    delete messageTemplate.input_url;
  }
  if (tType === DEF_TEMPLATE_TYPES.CARD) {
    const isTitleRequired = configs?.card?.title?.required;
    const isSubtitleRequired = configs?.card?.sub_title?.required;
    if (messageTemplate.content) {
      const { content } = messageTemplate;
      const newArr = [];
      content.map((block) => {
        const newList = [];
        if (block['card-list']) {
          newArr.push(block);
        } else {
          block.forEach((temp) => {
            const { title, buttons, image } = temp;
            const subtitle = temp['sub-title'];
            const validButtons = buttons.filter(
              (btn) => btn.label.trim().length > 0
            );
            if (
              ((isTitleRequired ? title.trim().length > 0 : true) ||
                (isSubtitleRequired ? subtitle.trim().length > 0 : true)) &&
              validButtons.length > 0
            ) {
              const cc = {
                ...temp,
                'image-url': image['image-url'],
                'upload-type': image['upload-type'],
                buttons: validButtons.map((btn) => {
                  const cBtn = { ...btn };
                  delete cBtn.key;
                  if (cBtn['button-type'] === 'link') {
                    delete cBtn.message;
                  } else {
                    delete cBtn.link;
                  }
                  return cBtn;
                }),
              };
              delete cc.key;
              delete cc.image;
              newList.push(cc);
            }
          });
          newArr.push({ 'card-list': [...newList] });
        }
      });
      delete messageTemplate.content;
      messageTemplate.content = [...newArr];
    } else {
      const { template } = messageTemplate;
      const newList = [];
      template.forEach((temp) => {
        const { title, buttons, image } = temp;
        const validButtons = buttons.filter(
          (btn) => btn.label.trim().length > 0
        );
        if (
          ((isTitleRequired ? title.trim().length > 0 : true) ||
            (isSubtitleRequired ? subtitle.trim().length > 0 : true)) &&
          validButtons.length > 0
        ) {
          const cc = {
            ...temp,
            'image-url': image['image-url'],
            'upload-type': image['upload-type'],
            buttons: validButtons.map((btn) => {
              const cBtn = { ...btn };
              delete cBtn.key;
              if (cBtn['button-type'] === 'link') {
                delete cBtn.message;
              } else {
                delete cBtn.link;
              }
              return cBtn;
            }),
          };
          delete cc.key;
          delete cc.image;
          newList.push(cc);
        }
      });
      messageTemplate['card-list'] = [...newList];
      delete messageTemplate.template;
    }
  }
  if (tType === DEF_TEMPLATE_TYPES.QUICK) {
    if (messageTemplate.content) {
      const { content } = messageTemplate;

      const newArr = [];
      content.map((block) => {
        const replies = block['quick-replies'].filter(
          (reply) => reply.label.trim().length > 0
        );
        const newList = replies.map((re) => {
          const newValue = { ...re };
          delete newValue.key;
          return newValue;
        });
        newArr.push({
          text: block.text,
          template: block.text,
          'quick-replies': newList,
        });
      });
      messageTemplate.content = newArr;
    } else {
      const { template } = messageTemplate;
      const replies = messageTemplate['quick-replies'].filter(
        (reply) => reply.label.trim().length > 0
      );
      const newList = replies.map((re) => {
        const newValue = { ...re };
        delete newValue.key;
        return newValue;
      });
      messageTemplate['quick-replies'] = newList;
    }
  }
  if (tType === TEMPLATE_TYPES.FREE) {
    if (messageTemplate?.content) {
    } else {
      const { channels } = messageTemplate;
      const validChannels = channels.filter((cha) => {
        const { value } = cha;
        const tType = cha['template-type'];
        return (
          (tType === 'text' && value.text.trim().length > 0) ||
          (tType === 'code' && value.code.trim().length > 0)
        );
      });
      const newList = validChannels.map((valid) => {
        let { text } = valid.value;
        if (valid['template-type'] === 'code') {
          text = valid.value.code;
        }

        return {
          'channel-name': valid['channel-name'],
          'template-type': valid['template-type'],
          template: text,
        };
      });
      messageTemplate.channels = newList;
    }
  }
  return messageTemplate;
};

const invalidCard = (temp, optional, configs) => {
  const TITLE_MAX =
    configs?.card?.title?.char_max || RESPONSE_CARD_TITLE_MAX_LENGTH;
  const SUBTITLE_MAX = configs?.card?.sub_title?.char_max || 40;
  const isTitleRequired = configs?.card?.title?.required;
  const isSubtitleRequired = configs?.card?.sub_title?.required;

  if (!temp.image) {
    temp = {
      ...temp,
      image: {
        'upload-type': temp['upload-type'],
        'image-url': temp['image-url'],
        input_url: temp['image-url'],
      },
    };
  }

  if (optional) {
    const { image, title, buttons } = temp;
    const subtitle = temp['sub-title'];
    const validButtons = buttons.filter((btn) => btn.label.trim().length > 0);
    if (
      // title.trim().length > TITLE_MAX ||
      // subtitle.trim().length > SUBTITLE_MAX ||
      (image.input_url?.trim().length > 0 &&
        image['image-url'].trim().length < 1) ||
      (((image['upload-type'] === 'file' && image['image-url']) ||
        (image['upload-type'] === 'url' &&
          image.input_url?.trim().length > 0) ||
        subtitle.trim().length > 0) &&
        ((isTitleRequired ? title.trim().length < 1 : false) ||
          (isSubtitleRequired ? subtitle.trim().length < 1 : false) ||
          validButtons.length < 1)) ||
      (title.trim().length > 0 && validButtons.length < 1) ||
      (((isTitleRequired ? title.trim().length < 1 : false) ||
        (isSubtitleRequired ? subtitle.trim().length < 1 : false)) &&
        validButtons.length > 0)
    ) {
      return true;
    }
  } else {
    const { image, title, buttons } = temp;
    const subtitle = temp['sub-title'];
    const validButtons = buttons.filter((btn) => btn.label.trim().length > 0);
    if (
      // title.trim().length > TITLE_MAX ||
      (isTitleRequired ? title.trim().length < 1 : false) ||
      // subtitle.trim().length > SUBTITLE_MAX ||
      (isSubtitleRequired ? subtitle.trim().length < 1 : false) ||
      (image['upload-type'] === 'url' &&
        image.input_url?.trim().length > 0 &&
        image['image-url'].trim().length < 1) ||
      validButtons.length < 1
    ) {
      return true;
    }
  }
  return false;
};

const returnTemplateValidation = (
  template,
  isOptional,
  fieldName,
  validFreeChannelLength = 1,
  configs
) => {
  const TEXT_MAX_LENGTH =
    configs?.text?.char_max ||
    configs?.text?.byte_max ||
    TEMPLATE_TEXT_MAX_LENGTH;
  const isTitleRequired = configs?.card?.title?.required;
  const isSubtitleRequired = configs?.card?.sub_title?.required;

  let err = {};
  if (isOptional) {
    // optional template
    if (template['template-type'] === TEMPLATE_TYPES.FREE) {
    } else {
      if (template['template-type'] === DEF_TEMPLATE_TYPES.TEXT) {
        if (template.template.trim().length > TEXT_MAX_LENGTH) {
          err = {
            required: true,
            errMsg: '',
          };
        }
      }
      if (template['template-type'] === DEF_TEMPLATE_TYPES.IMAGE) {
        if (template['upload-type'] === 'url') {
          const imgUrl = template['image-url'];
          if (imgUrl?.trim().length === 0) {
            err = {
              required: true,
              errMsg: '',
            };
          }
        }
      }

      if (template['template-type'] === DEF_TEMPLATE_TYPES.VIDEO) {
        if (template['upload-type'] === 'url') {
          const imgUrl = template['video-url'];
          if (imgUrl?.trim().length === 0) {
            err = {
              required: true,
              errMsg: '',
            };
          }
        }
      }

      if (template['template-type'] === DEF_TEMPLATE_TYPES.DOCUMENT) {
        if (template['upload-type'] === 'url') {
          const imgUrl = template['document-url'];
          if (imgUrl?.trim().length === 0) {
            err = {
              required: true,
              errMsg: '',
            };
          }
        }
      }

      if (template['template-type'] === DEF_TEMPLATE_TYPES.CARD) {
        let { template: cardTemps } = template;
        if (!Array.isArray(cardTemps) && cardTemps?.['card-list']) {
          cardTemps = cardTemps?.['card-list'];
        }
        const issued = [];
        cardTemps?.forEach((temp) => {
          const result = invalidCard(temp, true, configs);
          if (result) {
            issued.push(temp.key);
          }
        });

        if (issued.length > 0) {
          err = {
            cards: issued,
          };
        }
      }

      if (template['template-type'] === DEF_TEMPLATE_TYPES.QUICK) {
        const { template: quickTemp } = template;
        const validButtons = template['quick-replies'].filter(
          (btn) => btn.label.trim().length > 0
        );
        if (
          (quickTemp.trim().length > 0 && validButtons.length < 1) ||
          (quickTemp.trim().length <= 0 && validButtons.length >= 1)
        ) {
          err = {
            quick_reply: {
              required: true,
              errMsg: '',
            },
          };
        }
      }

      if (template['template-type'] === DEF_TEMPLATE_TYPES.DATE) {
        const {
          template: { datetime: dateTemp },
        } = template;
        const {
          'button-label': buttonLabel,
          'date-limit-type': dateLimitType,
          format,
          'max-date': maxDate,
          'min-date': minDate,
          type,
        } = dateTemp;

        if (dateLimitType === 'limited' && (!maxDate || !minDate)) {
          err = {
            datetime: {
              required: true,
              errMsg: '',
            },
          };
        }

        if (!type || !buttonLabel || !format) {
          err = {
            datetime: {
              required: true,
              errMsg: '',
            },
          };
        }
      }
    }
  } else {
    // required template
    if (template['template-type'] === TEMPLATE_TYPES.FREE) {
      // FREE
      const { channels } = template;
      const validChannels = channels.filter((cha) => {
        const { value } = cha;
        const tType = cha['template-type'];
        return (
          (tType === 'text' && value.text.trim().length > 0) ||
          (tType === 'code' && value.code.trim().length > 0)
        );
      });
      if (validChannels.length < validFreeChannelLength) {
        err = {
          free: {
            required: true,
            errMsg: '',
          },
        };
      }
    } else {
      // TEMPLATE
      if (template['template-type'] === DEF_TEMPLATE_TYPES.TEXT) {
        let textTemp = template;
        if (template.template !== undefined) {
          textTemp = template.template;
        }
        if (
          textTemp.trim().length > TEXT_MAX_LENGTH ||
          textTemp.trim().length < 1
        ) {
          let msg = `Please enter a ${fieldName}.`;
          if (textTemp.trim().length > 0) {
            msg = '';
          }

          err = {
            required: true,
            errMsg: msg,
          };
        }
      }

      if (template['template-type'] === DEF_TEMPLATE_TYPES.IMAGE) {
        let imageTemp = template;
        if (template.template) {
          imageTemp = template.template;
        }
        const url = imageTemp['image-url'];
        if (!url) {
          err = {
            required: true,
            errMsg: '',
          };
        }
      }

      if (template['template-type'] === DEF_TEMPLATE_TYPES.VIDEO) {
        let imageTemp = template;
        if (template.template) {
          imageTemp = template.template;
        }
        const url = imageTemp['video-url'];
        if (!url) {
          err = {
            required: true,
            errMsg: '',
          };
        }
      }

      if (template['template-type'] === DEF_TEMPLATE_TYPES.DOCUMENT) {
        let imageTemp = template;
        if (template.template) {
          imageTemp = template.template;
        }
        const url = imageTemp['document-url'];
        if (!url) {
          err = {
            required: true,
            errMsg: '',
          };
        }
      }

      // Recheck this logic.
      // What if some pass and some fail?
      if (template['template-type'] === DEF_TEMPLATE_TYPES.CARD) {
        let { template: cardTemp } = template;
        cardTemp = Array.isArray(cardTemp) ? cardTemp : cardTemp['card-list'];
        const optionals = [];
        const passed = [];
        const failed = [];
        cardTemp.forEach((temp) => {
          const result = invalidCard(temp, true, configs);
          if (result) {
            optionals.push(temp.key);
          }

          const { title, buttons } = temp;
          const subtitle = temp['sub-title'];
          const validButtons = buttons.filter(
            (btn) => btn.label.trim().length > 0
          );
          if (
            (isTitleRequired ? title.trim().length > 0 : true) &&
            (isSubtitleRequired ? subtitle.trim().length > 0 : true) &&
            validButtons.length > 0
          ) {
            passed.push(temp.key);
          } else {
            failed.push(temp.key);
          }
        });

        // pass optional
        if (optionals.length > 0) {
          err = {
            cards: optionals,
          };
        } else if (failed.length > 0) {
          // if any fails handle the error logic here.
          err = {
            cards: [...failed],
          };
        }
      }

      // Recheck this logic.
      if (template['template-type'] === DEF_TEMPLATE_TYPES.QUICK) {
        let quickTemp = template;
        if (!template?.['quick-replies']) {
          quickTemp = template.template;
          quickTemp.text = quickTemp.template || quickTemp.text;
        } else {
          quickTemp.text = quickTemp.template;
        }
        const validButtons = quickTemp?.['quick-replies']?.filter(
          (btn) => btn.label.trim().length > 0
        );
        if (
          quickTemp.text?.trim().length === 0 ||
          quickTemp.text?.trim().length > TEXT_MAX_LENGTH ||
          validButtons.length < 1
        ) {
          err = {
            quick_reply: {
              required: true,
              errMsg: '',
            },
          };
        }
      }

      if (template['template-type'] === DEF_TEMPLATE_TYPES.DATE) {
        let dateTemp = template.template;

        if (template.template.datetime) {
          dateTemp = template.template.datetime;
        }

        const {
          'button-label': buttonLabel,
          'date-limit-type': dateLimitType,
          format,
          'max-date': maxDate,
          'min-date': minDate,
          type,
        } = dateTemp;

        if (dateLimitType === 'limited' && (!maxDate || !minDate)) {
          err = {
            datetime: {
              required: true,
              errMsg: '',
            },
          };
        }

        if (!type || !buttonLabel || !format) {
          err = {
            datetime: {
              required: true,
              errMsg: '',
            },
          };
        }
      }
    }
  }

  return err;
};

export const getFilenameFromURL = (url) => {
  let fileName = '';
  try {
    const { pathname } = new URL(url);
    const parts = pathname.split('/');
    const fileNameWithExtension = parts[parts.length - 1];

    // Remove the file extension
    fileName = fileNameWithExtension.replace(/\.[^/.]+$/, '');
  } catch (e) {
    console.log(e);
  }

  return fileName;
};

export const getAddChannelOrderedList = (
  channel,
  multiChannelList,
  addMultiChannelList
) => {
  const list = [...multiChannelList];

  if (channel.key === 'default') {
    list.unshift(channel);
  } else {
    list.push(channel);
  }

  const remChannelList = [...addMultiChannelList];
  const index = remChannelList.findIndex((re) => re.key === channel.key);
  remChannelList.splice(index, 1);
  list.sort(
    (a, b) =>
      CHANNEL_DISPLAY_ORDER.indexOf(a.value) -
      CHANNEL_DISPLAY_ORDER.indexOf(b.value)
  );
  const channelIndex = list.findIndex((li) => li.key === channel.key);

  return {
    list,
    channelIndex,
    remChannelList,
  };
};

export {
  returnRelationName,
  returnStepIcon,
  returnStepDesc,
  returnStepDescWithParam,
  returnTypeName,
  returnActionType,
  returnTypeLabel,
  returnSubjectTypeLabel,
  returnScopeModeLabel,
  returnTypeLabelForLocal,
  returnComponentSubjectLabel,
  returnTemplateType,
  returnAddComma,
  isValidDate,
  returnTemplate,
  returnTemplateValidation,
  invalidCard,
  returnIsTemplateEmpty,
};
